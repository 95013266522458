import { supportEmailId } from '@constants/map';
import { Button, Divider, Layout, Typography } from 'antd'
import longlogo from '../../images/landing/longlogo.svg';
import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { Title, Paragraph, Text } = Typography;
const { Content } = Layout;

const TermsAndConditions = () => {
  const navigate = useNavigate();
const handleBackClick = () => {
        navigate("/"); // Navigate back
    };
  return (
    <Layout style={{ backgroundColor: '#FFF4ED' }}>
      <header style={{backgroundColor: "#0a1526", position: "fixed", width: "100%" }}>
                <div className="container">
                    <div className="landingHeader">
                        <div className="headerContent">
                            <div className="sitelogo">
                                <img src={longlogo} alt="sitelogo" />
                            </div>
                           
                        </div>
                    </div>
                </div>
            </header>
      <Content style={{ padding: '50px', maxWidth: '900px', margin: '0 auto', marginTop: '70px' }}>
        <Typography>
        <div style={{display:'flex', alignItems: 'center', gap: '25%',marginBottom: "25px"}}>
                    <Button type="link" onClick={handleBackClick} icon={<ArrowLeftOutlined style={{ color: "#000", fontSize: "20px", display: "flex", alignItems: 'center' }} />} />
                    <div className='textAuthHeading' style={{ textAlign: 'center' }}>
                        Terms and <span>Conditions</span>
                    </div>
                    </div>
          <Paragraph>
            <b>Effective Date:</b> 24/09/2024
          </Paragraph>
          <Paragraph>
            Welcome to ReferUs Network! Please carefully read these Terms and Conditions ("Terms")
            before subscribing to our services. By subscribing to or using our services,
            you agree to comply with these Terms. If you do not agree to these Terms,
            please refrain from using our services.
          </Paragraph>

          <Divider />

          <Title level={2}>1. Overview</Title>
          <Paragraph>
            ReferUs Network ("We," "Us," "Our") is an online referral platform that offers
            subscription-based services. These Terms govern the use of all services provided
            by ReferUs Network through monthly and yearly subscriptions.
          </Paragraph>
          <Divider />

          <Title level={2}>2. Subscription Plans</Title>
          <Paragraph>
            ReferUs Network offers two subscription plans:
          </Paragraph>
          <ul>
            <li><b>Monthly Subscription Plan:</b>
              Requires a minimum commitment of 6 months.
            </li>
            <li><b>Yearly Subscription Plan:</b>
              Billed annually, offering a discounted rate.
            </li>
          </ul>
          <Paragraph>
            Upon subscribing, you agree to the terms and payment schedule of the chosen plan.
            All subscriptions are automatically renewed at the end of each billing cycle unless
            cancelled in accordance with Section 4.
          </Paragraph>

          <Divider />

          <Title level={2}>3. Pricing and Payment</Title>
          <Paragraph>
            The subscription fees are outlined on our website and may vary based on the plan selected.
          </Paragraph>
          <ul>
            <li><b>Monthly Plan:</b>
              Billed monthly, with a minimum subscription period of 6 months.
            </li>
            <li><b>Yearly Plan:</b>
              Billed once per year at a discounted rate compared to the monthly plan.
            </li>
          </ul>

          <Paragraph>
            All prices are stated in [currency] and are subject to applicable taxes.
            Payment must be made using the payment method provided at the time of purchase.
          </Paragraph>

          <Divider />

          <Title level={2}>4. Cancellation Policy</Title>
          <Paragraph>
            You may cancel your subscription by providing written notice to <Text strong>{supportEmailId}</Text>.
            The cancellation will become effective at the end of the current billing cycle.
          </Paragraph>
          <ul>
            <li><b>Monthly Subscription:</b>
              Cancellations are allowed after the 6-month minimum commitment. If you cancel before
              completing the 6-month minimum period, your subscription will remain active until
              the minimum commitment is fulfilled.
            </li>
            <li><b>Yearly Subscription: </b>
              Cancellations for yearly subscriptions will only take effect at the end of the current
              annual cycle. No partial refunds will be issued for cancellations before the cycle ends.
            </li>
          </ul>
          <Paragraph>
            Please allow up to 7 business days for cancellation requests to be processed.
          </Paragraph>
          <Divider />

          <Title level={2}>5. Automatic Renewal</Title>
          <Paragraph>
            All subscriptions will automatically renew at the end of the billing cycle unless cancelled
            in accordance with Section 4. The renewal will be charged at the current subscription rate. You will be notified via email prior to the renewal.
          </Paragraph>

          <Divider />

          <Title level={2}>6. Refund Policy</Title>
          <Paragraph>
            We do not offer refunds for any subscription plans. All payments made for monthly
            and yearly subscriptions are non-refundable, except in cases where the law requires
            otherwise. If you cancel your subscription, it will remain active until the end of
            the current billing cycle.
          </Paragraph>
          <Divider />

          <Title level={2}>7. Modifications to Subscription Fees and Services</Title>
          <Paragraph>
            ReferUs Network reserves the right to modify the subscription fees, service offerings,
            or any part of these Terms at any time. Any changes will be communicated to you in
            advance via email or our website. Your continued use of the services after such
            changes have been implemented constitutes acceptance of the revised Terms.
          </Paragraph>

          <Divider />

          <Title level={2}>8. Account Responsibilities</Title>
          <Paragraph>
            You are responsible for maintaining the confidentiality of your account credentials and for any
            activity that occurs under your account. You agree to notify us immediately of any
            unauthorized use of your account.
          </Paragraph>

          <Divider />

          <Title level={2}>9. Limitation of Liability</Title>
          <Paragraph>
            ReferUs Network is not liable for any indirect, incidental, special, or consequential
            damages arising from your use or inability to use the services. Our total liability to
            you will not exceed the amount you have paid for your subscription in the preceding 12
            months.
          </Paragraph>

          <Divider />

          <Title level={2}>10. Governing Law</Title>
          <Paragraph>
            These Terms are governed by and construed in accordance with the laws of New South
            Wales, Australia. Any disputes arising under or in connection with these Terms will
            be subject to the exclusive jurisdiction of the courts of New South Wales,
            Australia.
          </Paragraph>
          <Divider />

          <Title level={2}>11. Contact Information</Title>
          <Paragraph>
            For any inquiries, questions, or concerns about these Terms or our services, please contact us at:
          </Paragraph>
          <Paragraph>
            <Text strong>Email:</Text> {supportEmailId}
          </Paragraph>
          <Paragraph>
            By subscribing to ReferUs Network, you acknowledge that you have read, understood, and
            agree to these Terms and Conditions.
          </Paragraph>
        </Typography>
      </Content>
      <div className="footerbusiness">
                <div className="text-center">
                    <div className="footerbusinesstext">
                        © {new Date().getFullYear()} ReferUs , All rights
                        reserved
                    </div>
                </div>
            </div>
    </Layout>
  )
}

export default TermsAndConditions