// @ts-ignore
import { adminWebUrl } from '../../environment/environment';
import React, { useEffect, useState, useRef } from 'react';
import longlogo from '../../images/landing/longlogo.svg';
import aboutus from '../../images/landing/aboutsus.svg';
import fb from '../../images/landing/fb.svg';
import insta from '../../images/landing/insta.svg';
import x from '../../images/landing/x logo.svg';
import linkin from '../../images/landing/linkin.svg';
import footerlogo from '../../images/landing/fotterlogo.svg';
import { Link, useNavigate, useParams } from 'react-router-dom';
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos';
import './styles.css'

const ReferReferUsMarkettingPage = () => {
    const [mobilemenu, setmobilemenu] = useState(false);
    const { businessCode } = useParams();
    const [activePlan, setActivePlan] = useState('Yearly'); // Default selected is 'Multi Site'
    const navigate = useNavigate();
    const scrollRef = useRef(null);

    const handleCardClick = (plan: any) => {
        setActivePlan(plan); // Update the active plan based on the clicked card
    };

    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    const handleLinkClick = (
        e: React.MouseEvent<HTMLAnchorElement>,
        hash: string
    ) => {
        e.preventDefault(); // Prevent default anchor behavior
        const element = document.querySelector(hash);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        AOS.init();
    }, []);
    function handelmobileMenu() {
        setmobilemenu(!mobilemenu);
    }
    return (
        <div className="landIngPage">
            <header className='header-wrapper'>
                <div className="container">
                    <div className="landingHeader">
                        <div className="headerContent1">
                            <div className="sitelogo logo">
                                <img src={longlogo} alt="sitelogo" />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <section className="mainBanner mainBanner1">
                <div className="container">
                    <h1>
                        What’s the <span>best way</span> to market your business
                    </h1>
                    <h4>Manage your Word-of-Mouth Referrals</h4>
                    <p>
                        Get your happy clients referring you with the ReferUs
                        Network ®. When it comes to marketing your business
                        there's no one better than your happy customers and your
                        raving fans!
                    </p>
                    <div className="btnsgroups">
                        <button
                            className="btn btn-primary"
                            onClick={() => navigate(`/register/refer-refer-us/${businessCode}`)}
                        >
                            Sign Up Now
                        </button>
                        <button
                            className="btn btn-outline"
                            onClick={() => navigate('/contact-us')}
                        >
                            Find Out More
                        </button>
                    </div>
                </div>
            </section>
            <section ref={scrollRef} className="aboutus" id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <div className="aboutSideImg">
                                <img src={aboutus} alt="aboutus" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <h2 className="headings">
                                All About <span>Us</span>
                            </h2>
                            <ul className="listDesign">
                                <li>
                                    When you register to become a ReferUs
                                    Network ® company you are gaining the tools
                                    to market and network your business.
                                </li>
                                <li>
                                    Obtain new clients through our comprehensive
                                    and easy referral process. You will have
                                    your very own ReferUs Network landing page
                                    with your branding and logo. All you do is
                                    promote your ReferUs business page to your
                                    networks and customers and raving fans. You
                                    can also promote your business on all the
                                    Social Media platforms to gain an army or
                                    referrers for your business. This now
                                    magnifies your reach and the potential for
                                    more customers / clients.
                                </li>
                                <li>
                                    Once a referrer for your business registers
                                    via your ReferUs page, they receive a
                                    personalised link that they save to the home
                                    screen of their mobile phone. This is so
                                    they can easily refer you to new potential
                                    clients right from their phones!
                                </li>
                                <li>
                                    Now your army of referrers for your business
                                    can instantly refer a new and potential
                                    customer / client to you as they are having
                                    their conversation about how amazing you are
                                    as a business. IT’S INSTANT REFERRALS!
                                </li>
                                <li>
                                    Your customers / clients send you hot leads.
                                    It’s that simple!
                                </li>
                                <li>
                                    All the referrals from your agents are
                                    managed in your very own Admin section. An
                                    Incentive rewards page is also provided
                                    which gives your referrers an even better
                                    reason to promote your business for you.
                                </li>
                                <li>
                                    It's the cheapest and best way to market
                                    your business. You leverage your networks
                                    and happy clients to promote your business
                                    and take it to the next level
                                </li>
                                <li>
                                    Your customers and raving fans now become
                                    your greatest salespeople for your business.
                                </li>
                            </ul>
                            <div className="btnsgroups aboutUs">
                                <Link
                                    to={`/register/refer-refer-us/${businessCode}`}
                                    className="btn btn-primary h-auto"
                                >
                                    Sign Up Now
                                </Link>
                                <Link
                                    to="/contact-us"
                                    className="btn btn-primary h-auto"
                                >
                                    Contact Us
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* new section */}
            <section className="subsplans" id="plans-section">
                <div className="container">
                    <div className="text-center">
                        <h2 className="headings">
                            One Affordable<span> Plan for Your Needs</span>
                        </h2>
                        <p className="customeheads">
                            For the cost of a small advertisement in a local
                            paper you can purchase a marketing tool that can
                            transform your business. Business can purchase the
                            system either Monthly or Yearly. White label and
                            corporate solutions also available .
                        </p>
                        <div className="cardblock cardblock1">
                            <div
                                className={`cardsubs ${
                                    activePlan === 'Monthly'
                                        ? 'active aos-init aos-animate'
                                        : 'aos-init aos-animate'
                                }`}
                                data-aos="flip-left"
                                data-aos-duration="115000"
                                onClick={() => handleCardClick('Monthly')}
                            >
                                <div className="cardfirstTitle">Monthly</div>
                                <div className="cardSecondTitle">$35*</div>
                                <div className="geryblocked">
                                    <ul className="listnew">
                                        <li>ReferUs Admin Platform**</li>
                                        <li>ReferUs Mobile application</li>
                                        <li>ReferUs Network Platform</li>
                                        <li>
                                            Personalised QR Code and URL to
                                            promote on social media
                                        </li>
                                    </ul>
                                    <div className="text-center">
                                        <button
                                            onClick={() =>
                                                navigate(`/register/refer-refer-us/${businessCode}`, {
                                                    state: {
                                                        selectedPlan: 'monthly',
                                                    },
                                                })
                                            }
                                            className="chooseplan"
                                        >
                                            Choose Plan
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`cardsubs ${
                                    activePlan === 'Yearly'
                                        ? 'active aos-init aos-animate'
                                        : 'aos-init aos-animate'
                                }`}
                                data-aos="flip-left"
                                data-aos-duration="115000"
                                onClick={() => handleCardClick('Yearly')}
                            >
                                <div className="cardfirstTitle">Yearly</div>
                                <div className="cardSecondTitle">$375*</div>
                                <div className="geryblocked">
                                    <ul className="listnew">
                                        <li>ReferUs Admin Platform**</li>
                                        <li>ReferUs Mobile application</li>
                                        <li>ReferUs Network Platform</li>
                                        <li>
                                            Personalised QR Code and URL to
                                            promote on social media
                                        </li>
                                    </ul>
                                    <div className="text-center">
                                        <button
                                            onClick={() =>
                                                navigate(`/register/refer-refer-us/${businessCode}`, {
                                                    state: {
                                                        selectedPlan: 'yearly',
                                                    },
                                                })
                                            }
                                            className="chooseplan"
                                        >
                                            Choose Plan
                                        </button>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                        <p className="customeheads1">
                            * Tax not included <br />
                            <p>
                                ** Monthly: allows for 500 referrers, then
                                $15.00 for every 500 referrers <br />
                                Yearly: allows for 500 referrers, then $180.00
                                for every 500 referrers
                            </p>
                        </p>
                    </div>
                </div>
            </section>
            <footer>
                <div className="outerfooter">
                    <div className="container">
                        <div className="whitefooters">
                            <div className="textparts">
                                ReferUs Network :{' '}
                                <span>Grow your business with ReferUs</span>
                            </div>
                            <Link className="btn btn-primary h-auto" to={`/register/refer-refer-us/${businessCode}`}>
                                Sign Up Now
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="fottercontent">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-sm-12">
                                <div className="sitelogo">
                                    <img src={footerlogo} alt="sitelogo" />
                                </div>
                                <p>
                                    Get your happy clients referring you with
                                    ReferUs
                                </p>
                                <div className="socialIcons">
                                    <a href="#" target="_blank">
                                        <img src={fb} alt="facebook" />
                                    </a>
                                    <a href="#" target="_blank">
                                        <img src={insta} alt="instagram" />
                                    </a>
                                    <a href="#" target="_blank">
                                        <img src={x} alt="x" />
                                    </a>
                                    <a href="#" target="_blank">
                                        <img src={linkin} alt="LinkedIn" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-6 links">
                                <h3>Quick Links</h3>
                                <Link
                                    to="#about"
                                    onClick={(e) =>
                                        handleLinkClick(e, '#about')
                                    }
                                >
                                    About
                                </Link>
                                {/* <Link
                                    to="/"
                                    onClick={(e) =>
                                        handleLinkClick(e, '#process')
                                    }
                                >
                                    Process{' '}
                                </Link> */}
                                <Link
                                    to="/"
                                    onClick={(e) =>
                                        handleLinkClick(e, '#plans-section')
                                    }
                                >
                                    Plans
                                </Link>
                                <Link to="/contact-us">Contact</Link>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-6 links">
                                <h3>Contact </h3>
                                <Link to="/contact-us">Contact Us</Link>
                                <a
                                    href="/privacy-policy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Privacy Policy
                                </a>
                                <a
                                    href="/terms-and-conditions"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Terms & Conditions
                                </a>

                                <Link
                                    to="#"
                                    onClick={(e) => {
                                        e.preventDefault(); // Prevents React Router's internal navigation
                                        window.open(
                                            `${adminWebUrl}/login`,
                                            '_blank'
                                        );
                                    }}
                                >
                                    Sign In
                                </Link>
                                <Link to={`/register/refer-refer-us/${businessCode}`}>Register</Link>
                            </div>
                            <div className="col-lg-3 col-sm-12">
                                <h3>Subscribe Our Newsletter </h3>
                                <div className="subscriptionletter">
                                    <form>
                                        <input
                                            type="text"
                                            placeholder="Enter Your Email Here"
                                        />
                                        <button className="arrow button">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="25"
                                                viewBox="0 0 24 25"
                                                fill="none"
                                            >
                                                <path
                                                    d="M21.5 3.33984L14.85 22.3398L11.05 13.7898L2.5 9.98984L21.5 3.33984Z"
                                                    stroke="#F47523"
                                                    stroke-width="2"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M21.4998 3.33984L11.0498 13.7898"
                                                    stroke="#F47523"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="text-center copyright">
                        © {new Date().getFullYear()} ReferUs , All rights
                        reserved
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default ReferReferUsMarkettingPage;
