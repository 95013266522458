import Register from '@components/RegisterForm/Register'
import React from 'react'

const ReferReferUsRegister = () => {
    
  return (
    <div>
        <Register />
    </div>
  )
}

export default ReferReferUsRegister