export function formatAndCapitalizeString(str: string) {
    return str && str?.replace(/-/g, ' ')?.replace(/\b\w/g, match => match?.toUpperCase());
  }

  export function extractVideoId(url: string) {
    const urlRegex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url?.match(urlRegex);
    return (match && match[7]?.length === 11) ? match[7] : false;
  }

  export function getTokenFromUrl(url:string) {
    const urlParts = url?.split('/');
    return urlParts[urlParts?.length - 1];
}


export const createUniqueRandomStringGenerator = () => {
  const generatedStrings = new Set();

  const generateUniqueString = () => {
      const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let uniqueString;

      do {
          uniqueString = Array.from({ length: 8 }, () =>
              characters[Math.floor(Math.random() * characters.length)]
          ).join('');
      } while (generatedStrings.has(uniqueString));

      generatedStrings.add(uniqueString);
      return uniqueString;
  };

  return generateUniqueString();
};

let key:any = 9876543210
// Function to encrypt a number
export function encryptNumber(number:any, nKey = key) {
  return number ^ nKey;  // XOR the number with the key
}

// Function to decrypt the number
export function decryptNumber(encryptedNumber:any, nKey = key) {
  return encryptedNumber ^ nKey;  // XOR the encrypted number with the key
}