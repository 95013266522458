import './styles/index.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

import configureAppStore, { getPreloadedState } from './store/configureStore';
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos';
AOS.init();

// import AppContextProvider from './contexts/AppContextProvider';

// import { ToDoList } from '@components/ToDo/ToDoList';
import AppRoute from './route';

(async () => {
    const preloadedState = getPreloadedState();

    const root = createRoot(document.getElementById('root'));

    root.render(
        <React.StrictMode>
            <HelmetProvider>
                <ReduxProvider store={configureAppStore(preloadedState)}>
                    <AppRoute />
                </ReduxProvider>
            </HelmetProvider>
        </React.StrictMode>
    );
})();
