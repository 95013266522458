import { supportEmailId } from '@constants/map';
import { Button, Divider, Layout, Typography } from 'antd';
import longlogo from '../../images/landing/longlogo.svg';
import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { Title, Paragraph, Text } = Typography;
const { Content } = Layout;

const PrivacyPolicy = () => {
    const navigate = useNavigate();
    const emailIdLink = <a href={`mailto:${supportEmailId}`}>{supportEmailId}.</a>;

    const handleBackClick = () => {
        navigate("/"); // Navigate back
    };

    return (
        <Layout style={{ backgroundColor: '#FFF4ED' }}>
            <header style={{backgroundColor: "#0a1526", position: "fixed", width: "100%" }}>
                <div className="container">
                    <div className="landingHeader">
                        <div className="headerContent">
                            <div className="sitelogo">
                                <img src={longlogo} alt="sitelogo" />
                            </div>
                           
                        </div>
                    </div>
                </div>
            </header>
            <Content style={{ padding: '50px', maxWidth: '900px',margin: "0 auto", marginTop: '70px' }}>
                <Typography >
                    <div style={{display:'flex', alignItems: 'center', gap: '25%', marginBottom: "25px"}}>
                    <Button type="link" onClick={handleBackClick} icon={<ArrowLeftOutlined style={{ color: "#000", fontSize: "20px", display: "flex", alignItems: 'center' }} />} />
                    <div className='textAuthHeading' style={{ textAlign: 'center' }}>
                        Privacy <span>Policy</span>
                    </div>
                    </div>
                    <Paragraph>
                        <b>Effective Date:</b> 24/09/2024
                    </Paragraph>
                    <Paragraph>
                        ReferUs Network ("We," "Us," "Our") is committed to protecting your privacy and ensuring
                        the security of your personal information. This Privacy Policy outlines how we collect,
                        use, and protect the personal data you provide to us when using our website and services.
                    </Paragraph>
                    <Paragraph>
                        By accessing or using ReferUs Network’s website and services, you agree to this Privacy Policy.
                        If you do not agree with the terms outlined here, please do not use our services.
                    </Paragraph>

                    <Divider />

                    <Title level={2}>1. Information We Collect</Title>
                    <Paragraph>
                        We collect the following types of information to provide and improve our services:
                    </Paragraph>

                    <Title level={3}>1.1 Personal Information</Title>
                    <Paragraph>
                        We may collect personal information that you voluntarily provide when subscribing to our services, such as:
                    </Paragraph>
                    <ul>
                        <li>Name</li>
                        <li>Email Address</li>
                        <li>Mailing Address</li>
                        <li>Payment information (billing details, credit/debit card information)</li>
                        <li>Contact phone number</li>
                        <li>Company details (for business subscriptions)</li>
                    </ul>

                    <Title level={3}>1.2 Usage Data</Title>
                    <Paragraph>
                        We may automatically collect information about how you interact with our website and services, including:
                    </Paragraph>

                    <ul>
                        <li>IP address</li>
                        <li>Browser type and version</li>
                        <li>Device information</li>
                        <li>Pages visited on our site</li>
                        <li>Date and time of visit</li>
                        <li>Time spent on each page</li>
                        <li>Referring website or service</li>
                    </ul>

                    <Divider />

                    <Title level={2}>2. How We Use Your Information</Title>
                    <Paragraph>
                        We collect and use your information for the following purposes:
                    </Paragraph>
                    <ul>
                        <li><b>To provide services:</b>
                            To set up and manage your account, process payments, and provide customer support.
                        </li>
                        <li><b>To communicate with you:</b>
                            To send updates, respond to your inquiries, and provide important notices about your subscription or services.
                        </li>
                        <li><b>To improve our services:</b>
                            To analyse usage trends and improve our website functionality, performance, and user experience.
                        </li>
                        <li><b>To comply with legal obligations:</b>
                            To meet regulatory and legal requirements, including financial record-keeping and compliance with Australian laws.
                        </li>
                    </ul>

                    <Divider />

                    <Title level={2}>3. How We Share Your Information</Title>
                    <Paragraph>
                        We do not share your personal information with third parties except in the following circumstances:
                    </Paragraph>
                    <ul>
                        <li><b>Service Providers:</b>
                            We may share your information with trusted third-party service providers who assist us in running our business, such as payment processors, hosting providers, and customer support tools.
                        </li>
                        <li><b>Legal Obligations:</b>
                            We may disclose your information to comply with applicable legal requirements or respond to valid legal processes, such as court orders or subpoenas.
                        </li>
                        <li><b>Business Transfers:</b>
                            If ReferUs Network is involved in a merger, acquisition, or asset sale, your personal information may be transferred to the new owner as part of that transaction.
                        </li>
                    </ul>

                    <Paragraph>
                        We will never sell or rent your personal information to third parties for marketing purposes.
                    </Paragraph>

                    <Divider />

                    <Title level={2}>4. Data Security</Title>
                    <Paragraph>
                        We take data security seriously and implement appropriate technical and organizational
                        measures to protect your personal information from unauthorised access, disclosure,
                        or destruction. These measures include encryption, secure servers, and regular
                        security assessments.
                    </Paragraph>
                    <Paragraph>
                        However, no method of transmission over the internet or electronic storage is completely
                        secure. While we strive to protect your data, we cannot guarantee absolute security.
                    </Paragraph>

                    <Divider />

                    <Title level={2}>5. Data Retention</Title>
                    <Paragraph>
                        We will retain your personal information only for as long as necessary to fulfill the
                        purposes for which it was collected, including to meet legal, accounting, or reporting
                        requirements. When your personal information is no longer required, we will securely
                        dispose of it in accordance with applicable laws and regulations.
                    </Paragraph>

                    <Divider />

                    <Title level={2}>6. Your Rights</Title>
                    <Paragraph>
                        As an individual in Australia, you have certain rights regarding your personal data, including:
                    </Paragraph>
                    <ul>
                        <li><b>Access:</b>
                            You can request a copy of the personal information we hold about you.
                        </li>
                        <li><b>Correction:</b>
                            You have the right to request corrections to any inaccurate or incomplete personal data.
                        </li>
                        <li><b>Deletion:</b>
                            You may request the deletion of your personal data in certain circumstances, subject to our legal obligations.
                        </li>
                        <li><b>Restriction:</b>
                            You can request that we limit the processing of your personal data where applicable.
                        </li>
                        <li><b>Objection:</b>
                            You can object to the processing of your personal information for certain purposes, such as direct marketing.
                        </li>
                    </ul>
                    <Paragraph>To exercise any of these rights, please contact us at {emailIdLink}</Paragraph>
                    <Divider />

                    <Title level={2}>7. Cookies and Tracking Technologies</Title>
                    <Paragraph>
                        We use cookies and other tracking technologies to enhance your user experience and collect
                        usage data for analytics purposes. You can control cookie settings through your browser,
                        but disabling cookies may affect your ability to use certain features of our website.
                    </Paragraph>

                    <Divider />

                    <Title level={2}>8. Third-Party Links</Title>
                    <Paragraph>
                        Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites.
                        We encourage you to review the privacy policies of any third-party sites you visit.
                    </Paragraph>

                    <Divider />

                    <Title level={2}>9. Changes to This Policy</Title>
                    <Paragraph>
                        We may update this Privacy Policy from time to time to reflect changes in our practices or
                        applicable laws. We will notify you of any significant changes by posting an updated policy
                        on our website. Your continued use of our services following any changes to this policy
                        constitutes acceptance of those changes.
                    </Paragraph>

                    <Divider />

                    <Title level={2}>10. Contact Us</Title>
                    <Paragraph>
                        If you have any questions or concerns about this Privacy Policy or how we handle your personal information, please contact us at:
                    </Paragraph>
                    <Paragraph>
                        <Text strong>Email:</Text> {emailIdLink}
                    </Paragraph>
                    <Paragraph>
                        By using ReferUs Network, you acknowledge that you have read, understood, and agreed to this Privacy Policy.
                    </Paragraph>
                </Typography>
            </Content>
            <div className="footerbusiness">
                <div className="text-center">
                    <div className="footerbusinesstext">
                        © {new Date().getFullYear()} ReferUs , All rights
                        reserved
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default PrivacyPolicy;
