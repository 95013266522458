import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Radio, Row } from 'antd';
import { useLocation } from 'react-router-dom';
// import MoonclerkPaymentForm from './MoonclerkPaymentForm';

export const PaymentForm = React.forwardRef(({ handlePayment, handlePlanValueChange, userId, paymentUrl }: any, ref) => {
  const [form] = Form.useForm();
  const [planType, setPlanType] = useState('monthly');
  const location = useLocation();
  const selectedPlan = location.state?.selectedPlan;
  let { NODE_ENV } = process?.env;
  let testPaymentList = ['none', 'development']

  const onFailed = (value: any) => {
    console.warn('Failed ::::::', value);
  };

  useEffect(() => {
    if (selectedPlan !== undefined) {
      setPlanType(selectedPlan);
      form.setFieldsValue({
        monthly: selectedPlan === 'monthly',
        yearly: selectedPlan === 'yearly',
        'multi-site': selectedPlan === 'multi-site',
      });
    }
  }, [selectedPlan, form]);

  const handlePlanChange = (e: any) => {
    const value = e.target.value;
    setPlanType(value);
    form.setFieldsValue({
      monthly: value === 'monthly',
      yearly: value === 'yearly',
      otherPlan: value === 'test',
      'multi-site': value === 'multi-site',
    });
  };

  const handlePlan = (value: any) => {
    setPlanType(value);
    handlePlanValueChange(value);
    form.setFieldsValue({
      monthly: value === 'monthly',
      yearly: value === 'yearly',
      otherPlan: value === 'test',
      'multi-site': value === 'multi-site',
    });

    handlePayment({
      monthly: value === 'monthly',
      yearly: value === 'yearly',
      otherPlan: value === 'test',
    })
  };

  return (
    <div className=''>
      <Row>
        <Col>
          <Form
            className='formControl'
            layout='vertical'
            form={form}
            onFinish={handlePayment}
            onFinishFailed={onFailed}
          >
            <label>Select Your Plan</label>
            <div className='planblock paymentSection'>
              <Radio.Group onChange={handlePlanChange} value={planType}>
                <div className={`planDetails ${planType === 'monthly' ? "active" : ""}`} onClick={() => handlePlan("monthly")}>
                  <div className='choosePlans'>
                    <div className='planOptions'>
                      <div className='radiodesign'>
                        <Radio value='monthly'></Radio>
                      </div>
                      <div className='planInfos'>
                        <div className='planHeadings'>Monthly ($35.00)</div>
                        <div className='planinfoList'>
                          <ol>
                            <li>ReferUs Admin Platform</li>
                            <li>ReferUs Mobile application</li>
                            <li>ReferUs Network Platform</li>
                            <li>Personalized QR Code and URL to promote on social media</li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`planDetails ${planType === 'yearly' ? "active" : ""}`} onClick={() => handlePlan("yearly")}>
                  <div className='choosePlans'>
                    <div className='planOptions'>
                      <div className='radiodesign'>
                        <Radio value='yearly'></Radio>
                      </div>
                      <div className='planInfos'>
                        <div className='planHeadings'>Yearly ($375.00)</div>
                        <div className='planinfoList'>
                          <ol>
                            <li>ReferUs Admin Platform</li>
                            <li>ReferUs Mobile application</li>
                            <li>ReferUs Network Platform</li>
                            <li>Personalized QR Code and URL to promote on social media</li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {testPaymentList?.includes(NODE_ENV) ? <div className={`planDetails ${planType === 'test' ? "active" : ""}`} onClick={() => handlePlan("test")}>
                  <div className='choosePlans'>
                    <div className='planOptions'>
                      <div className='radiodesign'>
                        <Radio value='test'></Radio>
                      </div>
                      <div className='planInfos'>
                        <div className='planHeadings'>Test ($.50)</div>
                        <div className='planinfoList'>
                          <ol>
                            <li>Test Plan (Will be removed before we go live)</li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> :""}
                {/* <div className={`planDetails ${planType === 'multi-site' ? "active" : ""}`} onClick={() => handlePlan("multi-site")}>
                  <div className='choosePlans'>
                    <div className='planOptions'>
                      <div className='radiodesign'>
                        <Radio value='multi-site'></Radio>
                      </div>
                      <div className='planInfos'>
                        <div className='planHeadings'>Multi Site ($300.00 p/y)</div>
                        <div className='planinfoList'>
                          <ol>
                            <li>ReferUs Admin Platform</li>
                            <li>ReferUs Mobile application</li>
                            <li>ReferUs Network Platform</li>
                            <li>Personalised QR Code and URL to promote on social media</li>
                            <li>For business with more than 10 sites up to 100 sites</li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </Radio.Group>
            </div>

            <Form.Item
              name='monthly'
              hidden={true}
              initialValue={true} // Default to monthly
            >
              <input type='hidden' />
            </Form.Item>

            <Form.Item
              name='yearly'
              hidden={true}
              initialValue={false}
            >
              <input type='hidden' />
            </Form.Item>
            <Form.Item
              name='multi-site'
              hidden={true}
              initialValue={false}
            >
              <input type='hidden' />
            </Form.Item>

            <div className='planinfoList' style={{ textAlign: "center" }}>
              {/* <ol> */}
              <li>* Tax not included</li>
              <li><p>Monthly: allows for 100 referrers, then $15.00 for every 500 referrers</p></li>
              <li><p>Yearly: allows for 100 referrers, then $180.00 for every 500 referrers</p></li>
              {/* </ol> */}
            </div>

            <br />
            {/* **** commented for testing payment on same screen **********  */}
            {/* <Button
              className='btn btn-primary w-100'
              onClick={() => form.submit()}
              type='primary'
            >
              Make Payment
            </Button> */}
          </Form>
        </Col>
      </Row>
    </div>
  );
});