import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
// @ts-ignore
import { googleMapAPIKey } from '../../environment/environment';
import markerIcon from '../../images/icon/mapMarker.png'; //

interface MapComponentProps {
    lat: number;
    lng: number;
}

const MapComponent: React.FC<MapComponentProps> = ({ lat, lng }) => {
    const [isMapLoaded, setIsMapLoaded] = useState(false);

    const mapStyles = {
        height: "350px",
        width: "100%"
    };

    const defaultCenter = {
        lat: Number(lat) || 30.707600,
        lng: Number(lng) || 76.715126
    };

    useEffect(() => {
        setTimeout(() => {
            if (window.google && window.google.maps) {
                setIsMapLoaded(true);
            }
        },2000)
    }, []);

    return (
        <LoadScript googleMapsApiKey={googleMapAPIKey} onLoad={() => setIsMapLoaded(true)}>
            <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={13}
                center={defaultCenter}
            >
                {isMapLoaded && (
                    <MarkerF
                        position={defaultCenter}
                        icon={{
                            url: markerIcon, // Custom marker icon
                            scaledSize: window && new (window.google.maps.Size)(25, 35) // Set size of the marker icon
                        }}
                    />
                )}
            </GoogleMap>
        </LoadScript>
    );
};

export default MapComponent;
