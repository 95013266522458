// just a blank webmap
export const WEB_MAP_ID = '5f3b7605b3364e7bb2416c93fae00995';

export const socialMediaList = [
    {
        value: "facebook",
        label: "Facebook"
    },
    {
        value: "instagram",
        label: "Instagram"
    },
    {
        value: "linkedin",
        label: "LinkedIn"
    },
]

export const businessCategories = [
    { value: "accommodation-and-hotels", label: "Accommodation and hotels" },
    { value: "agriculture", label: "Agriculture" },
    { value: "arts-culture-entertainment", label: "Arts Culture, entertainment" },
    { value: "construction", label: "Construction" },
    { value: "education-and-training", label: "Education and training" },
    { value: "fashion", label: "Fashion" },
    { value: "financial-and-insurance", label: "Financial and insurance" },
    { value: "fitness-and-sport", label: "Fitness and sport" },
    { value: "gardening-and-lawnmowing", label: "Gardening and Lawnmowing" },
    { value: "hairdressing", label: "Hairdressing" },
    { value: "healthcare", label: "Healthcare" },
    { value: "landscaping", label: "Landscaping" },
    { value: "marketing-and-advertising", label: "Marketing and Advertising" },
    { value: "mining", label: "Mining" },
    { value: "ndis", label: "NDIS" },
    { value: "plumbing", label: "Plumbing" },
    { value: "retail-trade", label: "Retail Trade" },
    { value: "security", label: "Security" },
    { value: "tradie", label: "Tradie" },
    { value: "wholesale-trade", label: "Wholesale trade" },
    { value: "other", label: "other" }
];

export const supportEmailId = "info@ReferUsNetwork.com";