import { RouteConfig } from '@typing/global';
import AuthRoutes from './authRoutes';

// router file
export const Routes: RouteConfig[] = [
    //  here routes will be defined
];

// export const AdminRoutes: any[] = [...AuthRoutes, ...BusinessAdminRoutes];
export const AdminRoutes: any[] = [...AuthRoutes];
