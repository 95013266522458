import React, { useState, ReactNode } from 'react';

const WebLayout: React.FC<{ children: ReactNode }> = ({ children }) => {

    return (
        <div className="">
            {/* <!-- ===== Main Content Start ===== --> */}
            <main className="bg-gray h-100vh">
                <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
                    {children}
                </div>
            </main>
            {/* <!-- ===== Main Content End ===== --> */}
        </div>
    );
};

export default WebLayout;
