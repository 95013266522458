/* eslint-disable no-undef */
/*
 * @file: index.js
 * @description: It Contain environment variables.
 * @date: 26-09-2024
 * @author: Maheshvar
 */

//For local setup
const local = {
    apiUrl: 'http://localhost:', //local
    adminWebUrl: 'http://localhost:8080',
    markettingWebUrl: 'https://ss.stagingsdei.com:3380',
    googleMapAPIKey: 'AIzaSyCioWN9xHJftoRC9ncmX-OrEYOb2wulfyo',
    centPaymentUrl: `https://app.moonclerk.com/pay/kjra99498ml`,
    stagingCentPaymentUrl: `https://app.moonclerk.com/pay/47u7s7mspwft`,
    monthlyPaymentUrl: `https://app.moonclerk.com/pay/hswe355qpab`,
    yearlyPaymentUrl: `https://app.moonclerk.com/pay/44xyuu9lrw3`,
    version: '/api/v1',
    PORT: '4002',
};

//For pre-prod setup
const preprod = {
    apiUrl: 'http://localhost:', //pre-prod
    adminWebUrl: 'https://ss.stagingsdei.com:3383',
    markettingWebUrl: 'https://ss.stagingsdei.com:3380',
    centPaymentUrl: `https://app.moonclerk.com/pay/kjra99498ml`,
    stagingCentPaymentUrl: `https://app.moonclerk.com/pay/47u7s7mspwft`,
    monthlyPaymentUrl: `https://app.moonclerk.com/pay/hswe355qpab`,
    yearlyPaymentUrl: `https://app.moonclerk.com/pay/44xyuu9lrw3`,
    googleMapAPIKey: 'AIzaSyCioWN9xHJftoRC9ncmX-OrEYOb2wulfyo',
    PORT: '4001',
};

//For dev server
const dev = {
    apiUrl: 'http://54.215.36.15:',
    adminWebUrl: 'https://ss.stagingsdei.com:3383',
    markettingWebUrl: 'https://ss.stagingsdei.com:3380',
    centPaymentUrl: `https://app.moonclerk.com/pay/kjra99498ml`,
    stagingCentPaymentUrl: `https://app.moonclerk.com/pay/47u7s7mspwft`,
    monthlyPaymentUrl: `https://app.moonclerk.com/pay/hswe355qpab`,
    yearlyPaymentUrl: `https://app.moonclerk.com/pay/44xyuu9lrw3`,
    googleMapAPIKey: 'AIzaSyCioWN9xHJftoRC9ncmX-OrEYOb2wulfyo',
    version: '/api/v1',
    PORT: '3384',
};

//For production server
const production = {
    apiUrl: 'https://api.referusnetwork.com',
    adminWebUrl: 'https://member.referusnetwork.com',
    markettingWebUrl: 'https://referusnetwork.com',
    centPaymentUrl: `https://app.moonclerk.com/pay/kjra99498ml`,
    stagingCentPaymentUrl: `https://app.moonclerk.com/pay/47u7s7mspwft`,
    monthlyPaymentUrl: `https://app.moonclerk.com/pay/hswe355qpab`,
    yearlyPaymentUrl: `https://app.moonclerk.com/pay/44xyuu9lrw3`,
    googleMapAPIKey: 'AIzaSyCioWN9xHJftoRC9ncmX-OrEYOb2wulfyo',
    version: '/api/v1',
    PORT: '',
};

//For staging server port KEY can not be remove its using in application
const staging = {
    apiUrl: 'https://ss.stagingsdei.com:',
    adminWebUrl: 'https://ss.stagingsdei.com:3383',
    markettingWebUrl: 'https://ss.stagingsdei.com:3380',
    centPaymentUrl: `https://app.moonclerk.com/pay/kjra99498ml`,
    stagingCentPaymentUrl: `https://app.moonclerk.com/pay/47u7s7mspwft`,
    monthlyPaymentUrl: `https://app.moonclerk.com/pay/hswe355qpab`,
    yearlyPaymentUrl: `https://app.moonclerk.com/pay/44xyuu9lrw3`,
    googleMapAPIKey: 'AIzaSyCioWN9xHJftoRC9ncmX-OrEYOb2wulfyo',
    version: '/api/v1',
    PORT: '3384',
};

if (process.env.NODE_ENV === 'development') module.exports = local;
// else if (process.env.NODE_ENV === 'none') module.exports = dev;
else if (process.env.NODE_ENV === 'none') module.exports = staging;
else if (process.env.NODE_ENV === 'preprod') module.exports = preprod;
else if (process.env.NODE_ENV === 'production') module.exports = production;
else module.exports = local;
