import { combineReducers } from 'redux';
import ToDo from './ToDo/reducer';
import Auth from './Auth/user';
import Business from './Business/business';
import General from './general';

export default combineReducers({
    ToDo,
    Auth,
    General,
    Business,
});
