import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AdminRoutes } from './routers';
import NotFoundPage from './pages/NotFoundPage';
import PaymentUnAuthorized from './pages/PaymentSuccess/PaymentUnAuthorized';

const AppRoute = () => {
    let adminRoutes = AdminRoutes;
    const role: number = 1;

    if (role === 1) {
        adminRoutes = AdminRoutes;
    }

    return (
        <Router>
            <Routes>
                {adminRoutes.map((route, index) => {
                    const { layout: Layout, component: Component } = route;
                    return (
                        <Route
                            key={index}
                            path={route.path}
                            element={
                                <Layout>
                                    <Component />
                                </Layout>
                            }
                        />
                    );
                })}
                 {/* Add a not found route */}
                 <Route path="*" element={<NotFoundPage />} />
                 <Route path="/payment-successful/un-authorized" element={<PaymentUnAuthorized />} />
            </Routes>
        </Router>
    );
};

export default AppRoute;
