/* eslint-disable @typescript-eslint/no-explicit-any */
import { PrivateLayoutProps } from '@typing/global';
import { Spin } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import whiteLogo from "../images/logo/whitelogo.png"
import authIcon from "../images/brand/authicons.png"

export const PrivateLayout: React.FC<PrivateLayoutProps> = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const isLoading = useSelector((state: any) => state?.General?.loading);
    const user = useSelector((state: any) => state?.user?.loggedIn);
    const homeUrl = useSelector((state: any) => state?.general?.home_url);

    if (user && location.pathname === '/login') {
        navigate(homeUrl ? homeUrl : '/dashboard', { replace: true });
    }

    return (
        <div className="privateWrapper d-flex align-items-center justify-content-center">
            <div className="cardWhite">
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <div className="sidebanner">
                            <div className="mainLogsContined">
                            <div className="whitelogos">
                            <img src={whiteLogo} alt="logo" />
                            </div>
                            </div>
                           
                            <div className="majoricons">
                            <img src={authIcon} alt="bannerImg" />
                            </div>
                           
                            <div className='authSidetext'>
                                <h5>Get your happy clients referring you with ReferUs. </h5>
                                <p>When it comes to marketing your business there's no one better than a happy customer</p>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">{isLoading ? <Spin fullscreen>{children}</Spin> : children}</div>
                </div>
            
            </div>
           
        </div>
    );
};
