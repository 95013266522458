import CustomSpinner from '@components/Comman/CustomLoader/CustomLoader';
import { Spin } from 'antd';
import React, { Suspense } from 'react'
// import Home from './Home'
const Home = React.lazy(()=> import('./Home'));

const HomeWrapper = () => {
  return (
    <Suspense fallback={<CustomSpinner />}>
        <Home />
    </Suspense>
  )
}

export default HomeWrapper