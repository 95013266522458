import { createSlice, PayloadAction } from '@reduxjs/toolkit';


export const initialAuthState: any = {
    error: null,
    businessList: [],
    businessDetails: {},
    defaultBusinessDetails: {},
    rewardList: [],
    businessIReferList: [],
    referralNotificationList: [],
    referUsNetworkList: [],
    contactUsList: []
};

const slice = createSlice({
    name: 'Business',
    initialState: initialAuthState,
    reducers: {
        businessGetSuccess: (state, action: PayloadAction<any>) => {
            state.businessList = action.payload;
            state.error = null;
        },
        businessReferUsgetSuccess: (state, action: PayloadAction<any>) => {
            state.referUsNetworkList = action.payload;
            state.error = null;
        },
        businessDtailsSuccess: (state, action: PayloadAction<any>) => {
            state.businessDetails = action.payload;
            state.error = null;
        },
        businessDefaultSuccess: (state, action: PayloadAction<any>) => {
            state.defaultBusinessDetails = action.payload;
            state.error = null;
        },
        businessIReferListSuccess: (state, action: PayloadAction<any>) => {
            state.businessIReferList = action.payload;
            state.error = null;
        },
        referralNotificationListSuccess: (state, action: PayloadAction<any>) => {
            state.referralNotificationList = action.payload;
            state.error = null;
        },
        rewardListSuccess: (state, action: PayloadAction<any>) => {
            state.rewardList = action.payload;
            state.error = null;
        },
        businessDetailsFailed: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
        businessGetFailed: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
        rewardListFailed: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        businessIReferListFailed: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        getContactUsSuccess: (state, action: PayloadAction<any>) => {
            state.contactUsList = action.payload;
            state.error = null;
        },
        getContactUsFailed: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
        businessDefaultFailed: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
        businessReferUsgetFailed: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
    },
});

const { reducer } = slice;

export const {
    businessGetSuccess,
    businessGetFailed,
    businessDtailsSuccess,
    businessDefaultSuccess,
    businessReferUsgetSuccess,
    businessReferUsgetFailed,
    businessDefaultFailed,
    businessDetailsFailed,
    rewardListSuccess,
    rewardListFailed,
    businessIReferListSuccess,
    referralNotificationListSuccess,
    businessIReferListFailed,
    getContactUsSuccess,
    getContactUsFailed
} = slice.actions;

export default reducer;
