import React from 'react'
import { MarketingLandingPage } from './MarketingLandingPage';
const ReferrerMarketing = () => {
  return (
    <div>
        <MarketingLandingPage />
    </div>
  )
}

export default ReferrerMarketing