import { businessCategories, socialMediaList } from '@constants/map';
import { Button, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { referReferUsAction } from '../../actions/business';
import CustomTooltip from '@components/Comman/CustomeTooltip/CustomTooltip';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const ReferUS = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const onFinish = (values: any) => {
        const payload = {
            companyName: values?.companyName,
            contactName: values?.contactName,
            position: values?.position,
            email: values?.email,
            mobileNumber: values?.mobileNumber,
            contactNumber: values?.contactNumber,
            address: values?.address,
        };

        dispatch(
            referReferUsAction(payload, (resp: any) => {
                console.log('resp :::::::::::::', resp);
                if(resp?.status === 201) {
                    navigate('/');
                }
            })
        );

    };
    const onFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
        // You can also display an error message to the user here
      };

      const validateMobileInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const allowedKeys = ['Backspace', 'Tab', 'Enter', 'ArrowLeft', 'ArrowRight', 'Delete'];
        const regex = /^[0-9()+\s]$/; // Allow digits, (), +, and spaces
    
        if (!regex.test(event.key) && !allowedKeys.includes(event.key)) {
            event.preventDefault();
        }
    };
  return (
      <div>
          <div className="authforms">
              <div className="authHeadings">
                  <button
                      onClick={() => navigate('/')}
                      className="btn textAuthHeading"
                  >
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                      >
                          <rect
                              x="0.4"
                              y="0.4"
                              width="23.2"
                              height="23.2"
                              rx="11.6"
                              stroke="#F47523"
                              stroke-width="0.8"
                          />
                          <path
                              d="M14 8L10 12L14 16"
                              stroke="#F47523"
                              stroke-width="1.25"
                              stroke-linecap="round"
                          />
                      </svg>{' '}
                  </button>
                  <div className="textAuthHeading">
                      Refer <span>ReferUs</span>
                  </div>
              </div>
                  <div style={{marginBottom: '3%', fontWeight: "500"}}>
                      Refer friends to ReferUs and earn rewards! <br />
                      <br />
                      For each Successful referral who purchases a yearly membership, you'll receive $50.
                      <br />
                      For each monthly membership referral, you'll earn $50, paid in two installments of $25 over the first two six-months periods.
                      <br />
                      Rewards apply only to successful referrals who purchase a membership with ReferUs.
                      <br />
                  </div>

              <Row>
                  <Col lg={24} md={24} sm={24}>
                      <Form
                          className="formControl"
                          layout="vertical"
                          form={form}
                          {...layout}
                          onFinish={onFinish}
                          onFinishFailed={onFailed}
                      >
                          <Form.Item
                              name="companyName"
                              className="formControlDesign"
                              label="Company Name"
                              tooltip="A mandatory field as the company name is important throughout the entire system. If you are part of a business chain or franchise enter the name and then enter your branch . i.e.: Super Cheap Auto - Blacktown"
                            //   rules={[
                            //       {
                            //           required: true,
                            //           message:
                            //               'Please input your comapny name!',
                            //       },
                            //   ]}
                          >
                              <Input placeholder="Company Name" />
                               {/* <CustomTooltip text={`A mandatory field as the company name is important throughout the entire system. If you are part of a business chain or franchise enter the name and then enter your branch . i.e.: Super Cheap Auto - Blacktown`} /> */}
                          </Form.Item>
                          {/* <Form.Item
                              name="abn"
                              label="ABN"
                              className="formControlDesign"
                              // validateStatus={validateAbn(abn).validateStatus}
                              // help={validateAbn(abn).errorMsg}
                              // rules={[
                              //     {
                              //         required: true,
                              //         message: 'Please input your ABN!',
                              //     }
                              // ]}
                          >
                              <Input
                                  placeholder="ABN"
                                  value={abn}
                                  onChange={handleAbnChange}
                              />
                          </Form.Item> */}
                          <Form.Item
                              name="contactName"
                              label="Contact Name"
                              className="formControlDesign"
                              tooltip="The main person using ReferUs"
                              rules={[
                                  {
                                      required: true,
                                      message: 'Please input contact!',
                                  },
                              ]}
                          >
                              <Input placeholder="Contact Name" />
                               {/* <CustomTooltip text={`The main person using ReferUs`} /> */}
                          </Form.Item>
                          <Form.Item
                              name="email"
                              label="Email Address"
                              className="formControlDesign"
                              tooltip="Mandatory field as this is how not only we communicate with you but your referrers and referrals"
                              rules={[
                                  {
                                      required: true,
                                      message: 'Please input your email!',
                                  },
                                  {
                                      type: 'email',
                                      message: 'The input is not valid E-mail!',
                                  },
                              ]}
                          >
                              <Input placeholder="Email Address" />
                               {/* <CustomTooltip text={`Mandatory field as this is how not only we communicate with you but your referrers and referrals`} /> */}
                          </Form.Item>

                          <Form.Item
                              name="position"
                              label="Position"
                              tooltip="Non-mandatory but may come in handy in a future update to complete this field"
                              className="formControlDesign"
                              // rules={[
                              //     {
                              //         required: true,
                              //         message: 'Please input position!',
                              //     }
                              // ]}
                          >
                              <Input placeholder="Position" />
                               {/* <CustomTooltip text={`Non-mandatory but may come in handy in a future update to complete this field`} /> */}
                          </Form.Item>

                          <Form.Item
                      className="formControlDesign"
                      label="Mobile Number"
                      tooltip="Mandatory field so the system can be opened up on your mobile phone."
                      name="mobileNumber"
                      rules={[
                        { required: true, message: 'Please input your Mobile Number!' },
                        {
                          validator: (_, value) => {
                            const countryCode = '+61'; // Australia country code
                            const phoneNumber = value.replace(countryCode, '');
                            if (phoneNumber) {
                                if (!phoneNumber.match(/^[0-9]+$/)) {
                                  return Promise.reject(new Error('Contact Mobile Number must only contain numbers!'));
                                } else if (phoneNumber.length > 10) {
                                  return Promise.reject(new Error('Contact Mobile Number must not exceed 10 digits!'));
                                } else if (phoneNumber.length < 10) {
                                  return Promise.reject(new Error('Contact Mobile Number must be exactly 10 digits!'));
                                } else {
                                    return Promise.resolve();
                                  }
                              } else {
                                return Promise.resolve();
                              }
                          },
                        },
                      ]}
                    >
                      <Input
                        addonBefore={'+61 '} // Display Australia country code by default
                        placeholder="Mobile Number"
                      />
                      {/* <CustomTooltip text={`Mandatory field so the system can be opened up on your mobile phone.`} /> */}
                    </Form.Item>

                          <Form.Item
                              name="contactNumber"
                              label="Contact Number"
                              tooltip="Non-mandatory"
                              className="formControlDesign"
                              // rules={[
                              //     {
                              //         required: true,
                              //         message: 'Please input contact number!',
                              //     }
                              // ]}
                          >
                              <Input placeholder="Company Contact Number" 
                              maxLength={14}
                              onKeyDown={validateMobileInput}
                              />
                               {/* <CustomTooltip text={`Non-mandatory`} /> */}
                          </Form.Item>

                          <Form.Item
                              name="address"
                              label="Address"
                              tooltip="A non mandatory field."
                              className="formControlDesign"
                          >
                              <Input placeholder="Enter Address" />
                               {/* <CustomTooltip text={`A non mandatory field.`} /> */}
                          </Form.Item>

                          {/* <Form.Item
                              name="paymentUrl"
                              label="Payment Url"
                              className="formControlDesign"
                          >
                              <Input
                                  disabled
                                  placeholder="Payment url"
                                  defaultValue={`https://app.moonclerk.com/pay/hswe355qpab/i/N45QKqKZXoMjsvqCfDaRRsJC`}
                              />
                          </Form.Item> */}

                          <Button
                              className="btn btn-primary w-100"
                              type="primary"
                              onClick={() => form.submit()}
                          >
                              Submit
                          </Button>
                      </Form>
                  </Col>
              </Row>
          </div>
      </div>
  );
}

export default ReferUS